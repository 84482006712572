import {StringRule} from './StringRule';
import {stringAtoms} from '@scripts/validation/atoms';

class PassRule extends StringRule {
    name = 'pass';

    equal(valueEqual) {
        this.constraint('equal', stringAtoms.equal, valueEqual);

        return this;
    }

    // testNumbers(message) {
    //     this.constraint('regexp', stringAtoms.testNumbers, message);

    //     return this;
    // }

    testRegexp(regexp) {
        this.constraint('regexp', stringAtoms.testRegexp, regexp);

        return this;
    }
}

export {PassRule};
