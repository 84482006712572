import {bootTemplate} from './bootTemplate';
import {bootInputmask} from './bootInputmask';
import {bootHeader} from './bootHeader';
import {bootAutoHeight} from './bootAutoHeight';

function bootAuthLayout() {
    const {componentStore} = bootTemplate();

    bootInputmask();
    bootHeader(componentStore);
    bootAutoHeight();

    return {componentStore};
}

export {bootAuthLayout};
