class Rule {
    #rawMessage;
    #message;
    #constraints = new Map();

    isValidateType = true;
    name = 'default';

    constructor(message = 'Invalid value') {
        this.setMessage(message);
    }

    methodNotImplementedGuard(method) {
        throw new Error(`Method "${method}" of validator rule "${this.name}" not implemented"`);
    }

    constraintNotFoundGuard(constraint) {
        throw new Error(`Constraint "${constraint}" not found for "${this.name}" validator rule`);
    }

    setMessage(message) {
        this.#rawMessage = message;

        return this;
    }

    get message() {
        return this.#message;
    }

    getConstraints() {
        return this.#constraints;
    }

    constraint(name, atom, args) {
        this.#constraints.set(name, [atom, args]);

        return this;
    }

    final() {
        this.#message = this.#rawMessage.replace(/:([a-z0-9-_]+)/gi, (...args) => {
            return this.#constraints.get(args[1])[1];
        });

        return this;
    }

    validateType(value) {
        this.methodNotImplementedGuard('validateType');
    }

    validateConstraint(name, value) {
        const constraint = this.#constraints.get(name);

        if (!constraint) {
            this.constraintNotFoundGuard(name);
        }

        const [atom, args] = constraint;

        return atom(value, args);
    }

    validate(value) {
        if (this.isValidateType && !this.validateType(value)) {
            return false;
        }

        for (const name of this.#constraints.keys()) {
            const valid = this.validateConstraint(name, value);

            if (!valid) {
                return false;
            }
        }

        return true;
    }
}

export {Rule};
