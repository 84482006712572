import {Component} from './Component';
import {EventEmitter} from "~/js/helpers";

class TimetableCard extends Component {

    #eventEmitter = new EventEmitter();
    #cardId;

    constructor(node, id) {
        super(node, id);

        this.#initCardId();
        this.#initDeleteButton();
        this.#initCancelButton();
        this.#initDeclineButton();
    }

    get cardId() {
        return this.#cardId;
    }

    handleCancel() {
        this.emit('cancel', this.cardId);
    }

    handleDelete() {
        this.emit('delete', this.cardId);
    }

    handleDecline() {
        this.emit('decline', this.cardId);
    }

    #initCardId() {
        const cardId = this.getAttribute('data-card-id');
        if(!cardId) return;

        this.#cardId = cardId;
    }

    #initCancelButton() {
        const cancelButton = this.getSubItem('cancel', null);
        if(!cancelButton) return;

        cancelButton.addEventListener('click', () => {
            this.handleCancel();
        })
    }

    #initDeleteButton() {
        const deleteButton = this.getSubItem('delete', null);
        if(!deleteButton) return;

        deleteButton.addEventListener('click', () => {
            this.handleDelete();
        })
    }

    #initDeclineButton() {
        const declineButton = this.getSubItem('decline', null);
        if(!declineButton) return;

        declineButton.addEventListener('click', () => {
            this.handleDecline();
        })
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);

}

export {TimetableCard}
