import {
    Component,
    Input,
    InputDelete,
    InputMultipleContainer,
    InputPassword,
    SelectSingle,
    SelectMultiple,
    Checkbox,
    Radio,
    Switcher,
    File,
    Logo,
    YandexMap,
    Overlay,
    Modal,
    Toasts,
    Datepicker,
    SwiperTabs,
    SwiperTabsLinks,
    SwiperSlider,
    Group,
    RadioGroup,
    Tooltip,
    Onboarding,
    TabsMin,
    Pagination,
    DaySection,
    RadioStars,
    Button,
    MeetingCard,
    SpeechCard,
    TimetableCard,
    CheckboxGroup,
    SpeakerQuestionModal,
    SpeakerRateModal,
    QuestionCard,
    TelegramModal,
} from '@scripts/components/index';

// TODO SOMETHING SMARTEST
const componentsMap = new Map([
    ['input', Input],
    ['inputDelete', InputDelete],
    ['inputMultipleContainer', InputMultipleContainer],
    ['inputPassword', InputPassword],
    ['selectSingle', SelectSingle],
    ['selectMultiple', SelectMultiple],
    ['checkbox', Checkbox],
    ['radio', Radio],
    ['switcher', Switcher],
    ['file', File],
    ['logo', Logo],
    ['yandexMap', YandexMap],
    ['overlay', Overlay],
    ['modal', Modal],
    ['toasts', Toasts],
    ['datepicker', Datepicker],
    ['swiperTabs', SwiperTabs],
    ['swiperSlider', SwiperSlider],
    ['swiperTabsLinks', SwiperTabsLinks],
    ['tooltip', Tooltip],
    ['onboarding', Onboarding],
    ['tabsMin', TabsMin],
    ['pagination', Pagination],
    ['daySection', DaySection],
    ['radioStars', RadioStars],
    ['button', Button],
    ['meetingCard', MeetingCard],
    ['timetableCard', TimetableCard],
    ['speechCard', SpeechCard],
    ['speakerQuestionModal', SpeakerQuestionModal],
    ['speakerRateModal', SpeakerRateModal],
    ['questionCard', QuestionCard],
    ['telegramModal', TelegramModal],
]);

const groupsMap = new Map([
    ['radioGroup', RadioGroup],
    ['checkboxGroup', CheckboxGroup],
]);

class Store {
    #components;
    #tags;

    constructor() {

        document.addEventListener('registrCollection', (event) => {
            this.registerCollection(event.detail.collection, event.detail.componentNode, event.detail.type);
        })
    }

    static #instanceNotFoundGuard(id) {
        throw new Error(`Component(-s) with id "${id}" not found`);
    }

    static #tagsNotFoundGuard(tag) {
        throw new Error(`Tags with tag "${tag}" not found`);
    }

    discover(target = document) {
        this.#components = {};
        this.#tags = {};

        const selector = [
            '[data-component]',
            '[data-component-id]',
            '[data-component-group]',
            '[data-component-tags]',
        ].join(', ');

        const nodes = Array.from(target.querySelectorAll(selector));

        for (const node of nodes) {
            const declare = node.dataset.component ?? null;
            const collection = node.dataset.componentCollection ?? null;
            const groupType = node.dataset.componentGroup ?? null;
            const tags = node.dataset.componentTags ?? null;
            let type = declare;
            let id = node.dataset.componentId ?? null;

            if (declare) {
                const declareMatches = declare.split(':');

                type = declareMatches[0];
                id = declareMatches[1] || id;
            }

            const cls = componentsMap.get(type) || Component;
            const component = new cls(node, id);

            if (groupType) {
                if (!this.#components[id]) {
                    this.#components[id] = new (groupsMap.get(groupType) || Group);
                }

                this.#components[id].add(component);
            } else if (id) {
                this.#components[id] = component;
            } else if (collection) {
                if (!this.#components[collection]) {
                    this.#components[collection] = [];
                }
                this.#components[collection].push(component);
            }

            if (tags) {
                this.#handleTags(node, tags);
            }
        }

        return this;
    }

    registerCollection(collection, componentNode, type = '') {
        const cls = componentsMap.get(type) || Component;
        const component = new cls(componentNode, null);
        this.#components[collection].push(component);
    }

    getById(id) {
        const component = this.#components[id];

        if (!component) {
            Store.#instanceNotFoundGuard(id);
        }

        return component;
    }

    getTags() {
        return this.#tags;
    }

    getTagsById(tag) {
        const tags = this.#tags[tag];

        if (!tags) {
            Store.#tagsNotFoundGuard(tag);
        }

        return tags;
    }

    all() {
        return this.#components;
    }

    #handleTags(node, rawTags) {
        const tags = rawTags.split(',');

        for (const tag of tags) {
            this.#handleTag(node, tag);
        }
    }

    #handleTag(node, tag) {
        if (!(tag in this.#tags)) {
            this.#tags[tag] = [];
        }

        this.#tags[tag].push(node);
    }
}

export {Store};
