class Group {
    #components = [];

    get components() {
        return this.#components;
    }

    add(component) {
        this.#components.push(component);
    }

    addEventListener(type, listener, options = null) {
        for (const component of this.#components) {
            component.addEventListener(type, listener, options = null);
        }

        return this;
    }

    removeEventListener(type, listener, options = null) {
        for (const component of this.#components) {
            component.removeEventListener(type, listener, options = null);
        }

        return this;
    }
}

export {Group};
