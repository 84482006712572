import {Component} from './Component';

class FormField extends Component {
    #field;
    #error;
    #converter;

    constructor(node, id) {
        super(node, id);

        this.#field = this.getSubItem('field', null);
        this.#error = this.getSubItem('error', null);
    }

    get field() {
        return this.#field;
    }

    get defaultPropertyTarget() {
        return this.field;
    }

    get rawValue() {
        return this.getProperty('value');
    }

    getValue() {
        const rawValue = this.rawValue;

        if (this.#converter) {
            try {
                return this.#converter(rawValue);
            } catch (e) {
                console.error(e);

                return null;
            }
        }

        return rawValue;
    }

    setValue(value) {
        this.getPropertyTarget('value').value = value;

        return this;
    }

    error(message) {
        if (!this.#error) return;

        if (message) {
            this.#error.textContent = message;
            this.#error.classList.remove('hidden');
        } else {
            this.#error.textContent = '';
            this.#error.classList.add('hidden');
        }

        return this;
    }

    useConverter(converter) {
        this.#converter = converter;

        return this;
    }

    show(showClass = null) {
        this.node.classList.remove(showClass || this.defaultShowClass);

        return this;
    }

    hide(showClass = null) {
        this.node.classList.add(showClass || this.defaultShowClass);

        return this;
    }

}

export {FormField};
