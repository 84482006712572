import {
    JsonTransformer as RequestJsonTransformer,
    FormTransformer as RequestFormTransformer,
} from './request';

import {
    TextTransformer as ResponseTextTransformer,
    JsonTransformer as ResponseJsonTransformer,
} from './response';

class Factory {
    static #requestTransformers = {
        json: RequestJsonTransformer,
        form: RequestFormTransformer,
    };

    static #responseTransformers = {
        text: ResponseTextTransformer,
        json: ResponseJsonTransformer,
    };

    static make(mode, transformer) {
        const transformers = mode === 'request' ? this.#requestTransformers : this.#responseTransformers;

        return new transformers[transformer]();
    }
}

export {Factory};
