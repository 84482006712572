import {Component} from "~/js/components/Component";
import {EventEmitter} from '@scripts/helpers';

class QuestionCard extends Component {
    #eventEmitter = new EventEmitter();
    #id;
    #answerBtn;
    #question;

    constructor(node, id) {
        super(node, id);

        this.#initId();
        this.#initAnswerButton();
        this.#initQuestion();
    }

    get id() {
        return this.#id;
    }

    get answerBtn() {
        return this.#answerBtn;
    }

    answer() {
        const isAnonymous = this.#answerBtn.dataset.isAnonymous === 'true';
        const speakerInfo = this.#answerBtn.dataset.speakerInfo;

        this.emit('answer', this.id, isAnonymous, speakerInfo, this.#question);
    }

    #initId() {
        this.#id = Number(this.node.getAttribute('data-id'));
    }

    #initAnswerButton() {
        const answerButton = this.getSubItem('answerBtn', null);

        if(!answerButton) return;

        answerButton.addEventListener('click', () => {
            this.answer();
        })

        this.#answerBtn = answerButton;
    }

    #initQuestion() {
        const question = this.getSubItem('question', null);

        if(!question) return;

        this.#question = question.innerText;
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);
}

export {QuestionCard}
