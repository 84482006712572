import {Component} from './Component';

class Button extends Component {
    #text;
    #leftIcon;
    #rightIcon;

    constructor(node, id) {
        super(node, id);

        this.#text = this.getSubItem('text', null);
        this.#leftIcon = this.getSubItem('leftIcon', null);
        this.#rightIcon = this.getSubItem('rightIcon', null);
    }

    setText(text) {
        if (!this.#text) {
            return;
        }

        this.#text.textContent = text;
    }

    setLeftIcon(icon) {
        if (!this.#leftIcon) {
            return;
        }

        this.#changeIcon(icon, this.#leftIcon);
    }

    setRightIcon(icon) {
        if (!this.#rightIcon) {
            return;
        }

        this.#changeIcon(icon, this.#rightIcon);
    }

    #changeIcon(icon, iconElement) {
        const svgUse = iconElement.querySelector('use');
        const iconPath = svgUse.getAttribute('xlink:href');
        const newIconPath = iconPath.split('#')[0] + '#' + icon;
        svgUse.setAttribute('xlink:href', newIconPath);
    }
}

export {Button};
