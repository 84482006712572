class Toasts {
    static PRESETS = {
        DATA_SAVED: 'data_saved',
        WARNING: 'warning',
    };

    static #component;
    static #presets = new Map();

    static component(component) {
        this.#component = component;

        return this;
    }

    static prepare(toast) {
        this.#component.prepare(toast);

        return this;
    }

    static add(message, options) {
        this.#component.add(message, options);

        return this;
    }

    static destroy(toast) {
        this.#component.destroy(toast);

        return this;
    }

    static remove(message) {
        this.#component.remove(message);

        return this;
    }

    static preset(name, options) {
        this.#presets.set(name, options);

        return this;
    }

    static addFromPreset(name, options = {}) {
        const presetOptions = this.#presets.get(name);
        const {message, ...rest} = Object.assign(presetOptions, options);

        this.add(message, rest);

        return this;
    }
}

export {Toasts};
