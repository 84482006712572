import Swiper from 'swiper';

import {Component} from './Component';
import {EventEmitter} from '@scripts/helpers';

class TabsMin extends Component {
    #eventEmitter = new EventEmitter();
    #tabs;
    #tabsIndexes;
    #keys;
    #selected = null;

    constructor(node, id) {
        super(node, id);

        const {tabs, tabsIndexes, keys} = Array
            .from(node.querySelectorAll('[data-tabs-min-key]'))
            .reduce((res, tab, i) => {
                const key = tab.dataset.tabsMinKey;

                tab.addEventListener('click', () => this.select(key));

                res.tabs[key] = tab;
                res.tabsIndexes[key] = i;

                res.keys.push(key);

                return res;
            }, {
                tabs: {},
                tabsIndexes: {},
                keys: [],
            });

        const active = node.dataset.active;

        this.#tabs = tabs;
        this.#tabsIndexes = tabsIndexes;
        this.#keys = keys;

        if (active) {
            this.select(active);
        }
    }

    get keys() {
        return this.#keys;
    }

    select(key) {
        const tab = this.#tabs[key];

        if(!tab) {
            return;
        }

        if (this.#selected) {
            this.#tabs[this.#selected].classList.remove('tabs-min__item--active');
        }

        tab.classList.add('tabs-min__item--active');

        this.emit('change', key, this.#selected);

        this.#selected = key;

        return this;
    }

    getSelected() {
        return this.#selected;
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);
}

export {TabsMin};
