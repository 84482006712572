import Swiper,  { Navigation}  from 'swiper';

import {Component} from './Component';
import {EventEmitter} from '@scripts/helpers';

Swiper.use([Navigation]);

class SwiperSlider extends Component {
    #eventEmitter = new EventEmitter();
    #swiper;
    #node;
    #spaceBetween;
    #selectorNavNext;
    #selectorNavPrev;
    #sliderWrapper;
    #slidesPerView;
    #slidesPerViewTablet;
    #slidesPerViewMobile;
    #selected = null;

    constructor(node, id) {
        super(node, id);


        const spaceBetween = node.getAttribute('data-space-between');
        const selectorNavNext = node.getAttribute('data-nav-next');
        const selectorNavPrev = node.getAttribute('data-nav-prev');
        let slidesPerView = node.getAttribute('data-slides-per-view');
        let slidesPerViewTablet = node.getAttribute('data-slides-per-view-tablet');
        let slidesPerViewMobile = node.getAttribute('data-slides-per-view-mobile');

        if (!slidesPerView) {
            slidesPerView = 'auto';
        }

        this.#node = node;
        this.#spaceBetween = spaceBetween;
        this.#selectorNavNext = selectorNavNext;
        this.#selectorNavPrev = selectorNavPrev;
        this.#slidesPerView = slidesPerView;
        this.#slidesPerViewTablet = slidesPerViewTablet ? slidesPerViewTablet : 3;
        this.#slidesPerViewMobile = slidesPerViewMobile ? slidesPerViewMobile : 2;
        this.#sliderWrapper = node.querySelector('.swiper-wrapper');

        this.#swiper = this.buildSlider();
    }

    buildSlider () {
        return new Swiper(this.#node, {
            slidesPerView: this.#slidesPerView,
            spaceBetween: +this.#spaceBetween,
            navigation: {
                nextEl: this.#selectorNavNext,
                prevEl: this.#selectorNavPrev,
            },
            breakpoints: {
                320: {
                  slidesPerView: this.#slidesPerViewMobile,
                },
                768: {
                  slidesPerView: this.#slidesPerViewTablet,
                },
                1024: {
                  slidesPerView: this.#slidesPerView,
                }
              }
        });
    }

    destroySlider() {
        while (this.#sliderWrapper.firstChild) {
            this.#sliderWrapper.removeChild(this.#sliderWrapper.firstChild);
        }

        this.#swiper.destroy();
    }

    getSliderWrapper() {
        return this.#sliderWrapper;
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);
}

export {SwiperSlider};
