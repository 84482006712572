import {Modal} from './Modal';
import { renderSpeaker } from '@scripts/helpers'

class SpeakerRateModal extends Modal {
    #templateSpeaker;
    #templatePresentation;
    #presentationItem;
    #presentationId;

    constructor(node, id) {
        super(node, id);

        this.#templateSpeaker = this.getSubItem('templateSpeaker');
        this.#templatePresentation = this.getSubItem('templatePresentation');
        this.#presentationItem = this.getSubItem('presentationItem');

    }

    get templateSpeaker() {
        return this.#templateSpeaker;
    }

    get presentationItem() {
        return this.#presentationItem;
    }

    get presentationId() {
        return this.#presentationId;
    }

    renderPresentationCard(speakersInfo, hall, event, eventId) {


        this.#presentationItem.innerHtml = '';
        this.#presentationItem.innerText = '';

        const presentationCard = this.#templatePresentation.content.cloneNode(true);

        const presentationCardTitle = presentationCard.querySelector('.presentation-card__title');
        const presentationCardHall = presentationCard.querySelector('.presentation-card__hall');
        const presentationCardSpeakers = presentationCard.querySelector('.presentation-card__speakers');

        presentationCardTitle.innerText = event;

        if (hall) {
            const presentationCardHallText = presentationCard.querySelector('span')
            presentationCardHallText.innerText = hall;
        } else {
            presentationCardHall.classList.add('hidden');
        }


        for(const speakerInfo of speakersInfo) {
            let speaker = this.#templateSpeaker.content.cloneNode(true);
            speaker = renderSpeaker(speaker, speakerInfo);
            presentationCardSpeakers.appendChild(speaker);
        }

        this.#presentationItem.dataset.eventId = eventId;
        this.#presentationItem.appendChild(presentationCard);

        this.#presentationId = eventId;
    }

}

export {SpeakerRateModal};
