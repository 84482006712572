import { Component } from './Component';

class Tooltip extends Component {
    #buttonOpen;
    #buttonClose;
    #tooltipBody;


    constructor(node, id) {
        super(node, id);

        this.#buttonClose = this.getSubItem('tooltipClose');
        this.#buttonOpen = this.getSubItem('tooltipOpen');
        this.#tooltipBody = this.getSubItem('tooltipBody');

        this.init();
    }

    open() {
        this.#tooltipBody.classList.add('tooltip--open');
    }

    close() {
        this.#tooltipBody.classList.remove('tooltip--open');
    }

    #maybeClose(event) {
        if (!this.node.contains(event.target)) {
            this.close();
        }

        return this;
    }

    init() {
        document.addEventListener('click', this.#maybeClose.bind(this));

        this.#buttonOpen.addEventListener('click', () => this.open());
        this.#buttonClose.addEventListener('click', () => this.close());
    }
}

export {Tooltip};
