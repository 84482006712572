import {bootNotifications} from './bootNotifications';
import {bootReminder} from './bootReminder';
import {yaTranslateInit} from "~/js/helpers/yatranslate";

function openRightMenu(rightMenu) {
    return () => {
        document.body.classList.add('scroll-lock');
        rightMenu.setAttribute('data-opened');
    }
}

function closeRightMenuFactory(rightMenu) {
    return (force = null) => target => {
        if (!force && target !== rightMenu.node) return;

        document.body.classList.remove('scroll-lock');
        rightMenu.removeAttribute('data-opened');
    };
}

function bootHeader(componentStore) {

    const {
        rightMenuOpener,
        rightMenu,
        rightMenuClose
    } = componentStore.all();

    yaTranslateInit();

    if(!rightMenuOpener || !rightMenu || !rightMenuClose) {
        return;
    }

    const closeRightMenu = closeRightMenuFactory(rightMenu);

    bootNotifications(componentStore);
    bootReminder(componentStore);

    rightMenuOpener.on('click', openRightMenu(rightMenu));
    rightMenu.on('click', closeRightMenu());
    rightMenuClose.on('click', closeRightMenu(true));
}

export {bootHeader};
