import {Component} from './Component';

class Switcher extends Component {
    #selected;
    #isSelectedCallback;
    #selectCallback;
    #unselectCallback;

    #callbackNotProvidedGuard(event) {
        throw new Error(`Callback for event "${event}" for component "${this.id}" not provided`);
    }

    initialize() {
        const items = Array.from(this.node.querySelectorAll('[data-item]'));

        if (!this.#isSelectedCallback) {
            this.#callbackNotProvidedGuard('isSelected');
        }

        if (!this.#selectCallback) {
            this.#callbackNotProvidedGuard('onSelect');
        }

        if (!this.#unselectCallback) {
            this.#callbackNotProvidedGuard('onUnselect');
        }

        for (const item of items) {
            if (this.#isSelectedCallback(item)) {
                this.#selected = item;
            }

            item.addEventListener('click', () => {
                if (this.#selected === item) return;

                const oldValue = this.#selected.dataset.item;
                const newValue = item.dataset.item;

                this.#unselectCallback(this.#selected, oldValue);
                this.#selectCallback(item, newValue);

                this.#selected = item;
            });
        }

        return this;
    }

    isSelected(callback) {
        this.#isSelectedCallback = callback;

        return this;
    }

    onSelect(callback) {
        this.#selectCallback = callback;

        return this;
    }

    onUnselect(callback) {
        this.#unselectCallback = callback;

        return this;
    }
}

export {Switcher};
