import {FormField} from './FormField';

class Radio extends FormField {

    constructor(node, id) {
        super(node, id);

        this.targets.set('disabled', node);
    }

    check(isChecked = true) {
        if (isChecked) {
            this.setAttribute('checked');
        } else {
            this.removeAttribute('checked');
        }
    }

    get isChecked() {
        return this.getProperty('checked');
    }
}

export {Radio};
