import {APIClient} from '../APIClient';
import {csrf} from '@scripts/helpers';

class Auth extends APIClient {
    getRegisterAuthSmsCode(phone) {
        return this.post(route('registrationAuthCode'), {
            body: {phone},
        });
    }

    verifyRegisterAuthSmsCode(phone, code) {
        return this.post(route('checkAuthCode'), {
            body: {
                phone,
                auth_code: code,
            },
        });
    }

    register(data) {
        return this.post(route('registration'), {
            body: data,
            headers: {
                'x-csrf-token': csrf(),
            },
        });
    }

    getLoginAuthSmsCode(phone) {
        return this.post(route('loginAuthCode'), {
            body: {phone},
        });
    }

    login(phone, code) {
        return this.post(route('login'), {
            body: {
                phone,
                auth_code: code,
            },
            headers: {
                'x-csrf-token': csrf(),
            },
        });
    }

    loginByEmail(email, password) {
        return this.post(route('loginByEmail'), {
            body: {email, password},
        });
    }

    forgotPassword(email) {
        return this.post(route('forgotPassword'), {
            body: {email},
        });
    }

    resetPassword(email, password, token) {
        return this.post(route('resetPassword'), {
            body: {email, password, token},
        });
    }

    checkEmail(email) {
        return this.get(route('checkEmail'), {
            query: {email}
        });
    }
}

export {Auth};
