import {events} from '@scripts/consts/events.js';

function metricYandex(event) {
    if (typeof ym !== "undefined") {
        ym(YANDEX_METRIKA_KEY, event.detail.type, event.detail.eventID);
    }
}

function metricGoogle(event) {
    if (typeof gtag !== 'undefined') {
        gtag('event', event.detail.eventID, {
            'event_category': event.detail.eventCategory
        });
    }
}

function metricVK() {
    const eventMap = {
        reg_return: 'registration',
        reg_continue: 'vk_reg_continue',
        reg_finish: 'vk_reg_finish',
        reg_get_code: 'vk_reg_get_code',
        entr_get_code: 'k_entr_get_code',
        entr_email: 'k_entr_email',
        reg_return_v: 'vk_reg_return',
    };

    return event => {
        if (typeof _tmr === 'undefined') return;

        const originalEventID = event.detail.eventID;
        const eventID = eventMap[originalEventID] ?? originalEventID;

        _tmr.push({
            type: event.detail.type,
            id: VK_TOP_KEY,
            goal: eventID,
        });
    };
}

function sendMetric(callback, name) {
    return event => {
        const only = event.detail.only;
        if (!only || only.includes(name)) {
            callback(event);
        }
    }
}

function bootMetrics() {
    if (METRICS_ENABLE) {
        document.addEventListener(events.metrics, sendMetric(metricYandex, 'yandex'));
        document.addEventListener(events.metrics, sendMetric(metricGoogle, 'google'));
        document.addEventListener(events.metrics, sendMetric(metricVK(), 'vk'));
    }
}

export {bootMetrics};
