import {EventEmitter} from '@scripts/helpers';

class Gate {
    #eventEmitter;
    #isValid = false;
    #invalid = new Set();
    #disabled = new Set();

    constructor() {
        this.#eventEmitter = new EventEmitter();
    }

    get isValid() {
        return this.#isValid;
    }

    valid(valid = true) {
        this.#isValid = valid;

        return this;
    }

    #invalidChanged() {
        const isValid = this.#invalid.size === 0;

        if (this.#isValid !== isValid) {
            this.#eventEmitter.emit(isValid ? 'valid' : 'invalid');

            this.#isValid = isValid;
        }
    }

    #validField(name) {
        this.#invalid.delete(name);
        this.#invalidChanged();
    }

    #invalidField(name) {
        this.#invalid.add(name);
        this.#invalidChanged();
    }

    addValidator(name, validator, disabled = false) {
        if (!validator.isValid) {
            if (disabled) {
                this.#disabled.add(name);
            } else {
                this.#invalid.add(name);
            }
        }

        validator.onValid(() => this.#validField(name));
        validator.onInvalid(() => this.#invalidField(name));

        return this;
    }

    disable(name) {
        if (this.#invalid.has(name)) {
            this.#disabled.add(name);
            this.#validField(name);
        }

        return this;
    }

    enable(name) {
        if (this.#disabled.has(name)) {
            this.#disabled.delete(name);
            this.#invalidField(name);
        }

        return this;
    }

    onValid(listener) {
        this.#eventEmitter.on('valid', listener);
        return this;
    }

    onInvalid(listener) {
        this.#eventEmitter.on('invalid', listener);
        return this;
    }
}

export {Gate};
