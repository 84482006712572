import {Component} from './Component';

class YandexMap extends Component {
    #maps;
    #map;

    static #mapsNotExistsGuard(namespace) {
        throw new Error(`Namespace "${namespace}" for yandex maps not exists`);
    }

    init(center, zoom, options = {}) {
        const namespace = this.dataset.namespace || 'ymaps';
        const maps = window[namespace];

        if (!maps) {
            YandexMap.#mapsNotExistsGuard(namespace);
        }

        this.#maps = maps;

        return new Promise(res => {
            maps.ready(() => {
                this.#map = new maps.Map(this.node, {center, zoom, ...options});

                res();
            });
        });
    }

    placeMark(coordinates, properties = {}, options = {}) {
        const placeMark = new this.#maps.Placemark(coordinates, properties, options);

        this.#map.geoObjects.add(placeMark);

        return this;
    }
}

export {YandexMap};
