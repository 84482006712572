import {Component} from './Component';
import {EventEmitter} from '@scripts/helpers';

class Onboarding extends Component {
    #eventEmitter;
    #buttonClose;
    #buttonNext;
    #step;
    #stepList;
    #componentStore;
    #card;
    #idElementToStick;
    #progressTitle;
    #progressBar;
    #isInit;

    constructor(node, id) {
        super(node, id);

        this.#eventEmitter = new EventEmitter();
        this.#step = 0;
        this.#buttonClose = this.getSubItem('onboardingClose');
        this.#buttonNext = this.getSubItem('onboardingNext');
        this.#card = this.getSubItem('onboardingCard');
        this.#stepList = this.node.querySelectorAll('[data-step]');
        this.#progressTitle = this.getSubItem('onboardingProgressTitle');
        this.#progressBar = this.getSubItem('onboardingProgressBar');
        this.#isInit = false;
    }

    addEventListener(type, listener, options = null) {
        this.#eventEmitter.on(type, listener);

        return this;
    }

    removeEventListener(type, listener, options = null) {
        this.#eventEmitter.off(type, listener);

        return this;
    }

    get isInit() {
        return this.#isInit;
    }

    next() {

        const stepItemCurrent = this.#stepList[this.#step];
        this.#step++;
        const stepItemNext = this.#stepList[this.#step];

        stepItemCurrent.classList.remove('is-active');
        stepItemNext.classList.add('is-active');

        if (this.#step === this.#stepList.length - 1) {
            this.#buttonNext.classList.add('hidden');
        }
    }

    #changePosition() {
        const stepItem = this.#stepList[this.#step];

        let scrollFunction;

        if (stepItem.dataset.to) {
            this.#idElementToStick = stepItem.dataset.to;

            scrollFunction = () => {
                this.setCardPosition();
            };

            this.setCardPosition();
            window.addEventListener('scroll', scrollFunction, false);

        } else {
            this.#idElementToStick = null;

            window.removeEventListener('scroll', scrollFunction, false);
        }

        this.setCardPosition();
        this.#changeProgress();
    }

    #changeProgress() {
        const countSteps = this.#stepList.length;
        const currentStep = this.#step + 1;

        const progress = Math.floor(currentStep * 100 / countSteps);

        this.#progressTitle.textContent = progress + '%';
        this.#progressBar.style.width = progress + '%';
    }

    setCardPosition() {
        const isVisibleElementToStick = this.#isVisibleElement(this.#idElementToStick);

        if (this.#idElementToStick && isVisibleElementToStick) {
            const position = this.#calcCardPosition(this.#idElementToStick);

            this.#card.style.position = 'absolute';
            this.#card.style.transform = `translate(${position.left}px, ${position.top}px)`;

            switch (position.direction) {
                case 'toRight':
                    this.#card.classList.add('onboarding-card--left');
                    this.#card.classList.remove('onboarding-card--top');
                    break;
                case 'toBottom':
                    this.#card.classList.add('onboarding-card--top');
                    this.#card.classList.remove('onboarding-card--left');
                    break;
            }

        } else {
            const classesToRemove = ['onboarding-card--left', 'onboarding-card--top'];
            this.#card.style.position = 'relative';
            this.#card.style.transform = `translate(0, 0)`;
            this.#card.classList.remove(...classesToRemove);
        }

    }

    #calcCardPosition(linkElement) {
        const linkTo = this.#componentStore.getById(linkElement);
        const windowWidth = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        const linkToPosition = linkTo.node.getBoundingClientRect();
        const parentPosition = this.node.getBoundingClientRect();
        const cardPosition = this.#card.getBoundingClientRect();

        const linkToPositionTopCenter = linkToPosition.top + linkToPosition.height / 2;
        const linkToPositionLeftCenter = linkToPosition.left + linkToPosition.width / 2;

        const cardHeightHalf = cardPosition.height / 2;
        const cardWidthHalf = cardPosition.width / 2;

        let left = linkToPosition.right - parentPosition.left + 30;
        let top = linkToPositionTopCenter - parentPosition.bottom - cardHeightHalf;
        let direction = 'toRight';

        // если посказка справа выходит за границу окна
        if (linkToPosition.right + cardPosition.width + 30 > windowWidth) {
            left = linkToPositionLeftCenter - parentPosition.left - cardWidthHalf;
            top = linkToPosition.bottom - parentPosition.bottom + 30;
            direction = 'toBottom';
        }

        // если подсказка снизу по центру элемента выходит за карй экрана справа
        if (linkToPositionLeftCenter + cardPosition.width > windowWidth) {
            left = linkToPosition.right - parentPosition.left - cardPosition.width;
        }

        // есди подсказка выходит за край экрана слева
        if (left < 0) {
            left = 0;
        }

        return {
            direction,
            top,
            left
        };
    }

    #isVisibleElement(idComponent) {
        if (idComponent === null) {
            return false;
        }
        const linkToElement = this.#componentStore.getById(idComponent);
        if (!linkToElement) {
            return false;
        }
        const linkToPosition = linkToElement.node.getBoundingClientRect();

        if (linkToPosition.width === 0 && linkToPosition.height === 0) {
            return false;
        }

        return true;
    }

    init(componentStore) {
        this.#isInit = true;
        this.#card.classList.remove('hidden');
        this.#componentStore = componentStore;
        this.#stepList[this.#step].classList.add('is-active');
        this.#changePosition();

        this.#buttonNext.addEventListener('click', () => {
            this.next();
            this.#changePosition();
        });

        this.#buttonClose.addEventListener('click', () => this.#eventEmitter.emit('close'));
    }
}

export {Onboarding};
