import {Factory} from './bodyTransformer';

class Response {
    #nativeResponse;
    #statusCode;
    #statusText;
    #ok;
    #body;

    constructor(nativeResponse) {
        this.#nativeResponse = nativeResponse;
        this.#statusCode = nativeResponse.status;
        this.#statusText = nativeResponse.statusText;
        this.#ok = nativeResponse.ok;
    }

    get nativeResponse() {
        return this.#nativeResponse;
    }

    get statusCode() {
        return this.#statusCode;
    }

    get statusText() {
        return this.#statusText;
    }

    get ok() {
        return this.#ok;
    }

    get body() {
        return this.#body;
    }

    async parseBody(bodyFormat) {
        try {
            this.#body = await Factory.make('response', bodyFormat).transform(this.#nativeResponse);
        } catch (e) {
            this.#body = null;
        }
    }
}

export {Response};
