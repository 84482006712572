import {PassRule} from '../rules';

const passPreset = new PassRule("Значение должно быть от :min символов, содержать цифры, латинские символы в верхнем и нижнем регистре")
    .min(8)
    // .testRegexp(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g)
    .testRegexp(/(?=(.*\d))(?=(.*[a-z]))(?=(.*[A-Z]))/)
    // .testRegexp(/[0-9]+/g)
    // .testRegexp(/[a-z]+/g)
    // .testRegexp(/[A-Z]+/g)
    .final();

export {passPreset};
