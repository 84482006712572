import {Component} from "./Component";
import {EventEmitter} from '@scripts/helpers';

class Pagination extends Component {
    #eventEmitter = new EventEmitter();
    #current;
    #total;

    constructor(node, id) {
        super(node, id);

        this.#total = this.#initTotal();
        this.#current = this.#initCurrent();

        this.#initNextButton();
        this.#initPrevButton();
        this.#initPageButtons();
    }

    changePage(page) {
        this.emit('change', page);
    }

    #initTotal() {
        const total = Number(this.node.getAttribute('data-total-pages'));

        if(!total || total < 0) {
            throw new Error(`Invalid total pages count (${total}) in component "Pagination"`);
        }

        return total;
    }

    #initCurrent() {
        const current = Number(this.node.getAttribute('data-current-page'));

        if(!current || current < 0 || current > this.#total) {
            throw new Error(`Invalid current page (${current}) in component "Pagination"`);
        }

        return current;
    }

    #initNextButton() {
        const nextButton = this.getSubItem('next');

        if (nextButton && this.#current !== this.#total) {
            nextButton.addEventListener('click', () => {
                this.changePage(this.#current + 1);
            })
        }
    }

    #initPrevButton() {
        const prevButton = this.getSubItem('prev');

        if (prevButton && this.#current !== 1) {
            prevButton.addEventListener('click', () => {
                this.changePage(this.#current - 1);
            })
        }
    }

    #initPageButtons() {
        const pages = this.node.querySelectorAll('[data-page]');

        Array.from(pages).forEach((pageButton) => {
            const page = Number(pageButton.getAttribute('data-page'));

            if (!page || page < 0 || page > this.#total) {
                throw new Error(`Invalid page format (${page}) in component Pagination`);
            }

            pageButton.addEventListener('click', () => {
                this.changePage(page);
            })
        })
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);
}

export { Pagination }
