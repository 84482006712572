function closeReminder(userLayoutReminder) {
    const node = userLayoutReminder.node;

    return () => {
        userLayoutReminder.invisible();
        setTimeout(() => node.parentNode.removeChild(node), 1000);
    };
}

function bootReminder(componentStore) {
    const {
        userLayoutReminder,
        userLayoutReminderCloseLink,
    } = componentStore.all();

    if (!userLayoutReminder) return;

    userLayoutReminderCloseLink.on('click', closeReminder(userLayoutReminder));
}

export {bootReminder};
