import { Component } from "./Component";

class DaySection extends Component {
    #header;
    #list;
    #btnListShow;
    #btnListHide;
    #isExpanded = false;
    #positionTop;

    constructor(node, id) {
        super(node, id);

        this.#header = this.getSubItem('header', null);
        this.#list = this.getSubItem('list', null);
        this.#btnListShow = this.getSubItem('list-show', null);
        this.#btnListHide = this.getSubItem('list-hide', null);

        if (this.#btnListShow) {
            this.#btnListShow.addEventListener('click', this.showList.bind(this));
        }

        if (this.#btnListHide) {
            this.#btnListHide.addEventListener('click', this.hideList.bind(this));
        }

        this.#positionTop = node.getBoundingClientRect().top + window.pageYOffset;
        window.addEventListener('scroll', this.fixHeader.bind(this));
    }

    showList() {
        this.#list.classList.remove('hidden');
        this.#list.style.maxHeight = this.#list.scrollHeight + 'px';
        this.#btnListShow.classList.add('invisible');
        this.#isExpanded = true;
    }

    hideList() {
        this.#list.style.maxHeight = null;
        this.#btnListShow.classList.remove('invisible');
        this.#list.classList.add('hidden');

        this.#isExpanded = false;

        window.removeEventListener('scroll', this.fixHeader.bind(this));

        window.scroll({
            top: this.#positionTop,
            left: 0,
        });
    }

    fixHeader() {
        if (this.#isExpanded) {
            const heightHeader = this.#header.scrollHeight;
            const nodeClientRect = this.node.getBoundingClientRect();
            const sectionWidth = nodeClientRect.width;
            const positionSectionTop = nodeClientRect.top;
            const positionSectionBottom = nodeClientRect.bottom - heightHeader;

            const lastEvent = this.#list.querySelector('.event:last-of-type');
            const lastEventPosition = lastEvent.getBoundingClientRect();
            const lastEventPositionTop = lastEventPosition.top - heightHeader;

            if (positionSectionTop <= 0 && positionSectionBottom >= 0) {
                this.#header.classList.add('fixed');
                this.#header.style.width = sectionWidth + 'px';
                this.node.style.paddingTop = heightHeader + 'px';

                if (lastEventPositionTop < 0) {
                    this.#header.style.top = lastEventPositionTop + 'px';
                } else {
                    this.#header.style.top = 0;
                }
            } else {
                this.#header.classList.remove('fixed');
                this.#header.style.width = null;
                this.node.style.paddingTop = null;
            }
        } else {
            this.#header.classList.remove('fixed');
            this.#header.style.width = null;
            this.node.style.paddingTop = null;
        }
    }
}

export { DaySection };
