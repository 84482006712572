import Inputmask from 'inputmask';

function bootInputmask(selector = '[data-inputmask]') {
    const nodes = document.querySelectorAll(selector);

    Inputmask.extendAliases({
        phone: {
            mask: '+7 (999) 999-99-99',
        },
        date: {
            mask: '99.99.9999',
        },
    });

    Inputmask().mask(nodes);
}

export {bootInputmask};
