function onEnter(node, callback, shouldFireCallback = null) {
    node.addEventListener('keydown', (...args) => {
        const [{keyCode}] = args;

        if (keyCode === 13) {

            if(typeof shouldFireCallback === 'function' && !shouldFireCallback()) {
                return;
            }

            callback(...args);
        }
    });
}

export {onEnter};
