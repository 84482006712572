import {Component} from "~/js/components/Component";
import {EventEmitter} from '@scripts/helpers';

class SpeechCard extends Component {
    #eventEmitter = new EventEmitter();
    #id;
    #questionButton;
    #rateButton;
    #scheduleButton;

    constructor(node, id) {
        super(node, id);

        this.#initId();
        this.#initQuestionButton();
        this.#initRateButton();
        this.#initScheduleButton();
    }

    get id() {
        return this.#id;
    }

    get questionButton() {
        return this.#questionButton;
    }

    get rateButton() {
        return this.#rateButton;
    }

    get scheduleButton() {
        return this.#scheduleButton;
    }

    question() {
        const speakerInfo = this.#questionButton.dataset.speakerInfo;
        const isAnonymous = this.#questionButton.dataset.isAnonymous;

        this.emit('question', this.id, JSON.parse(speakerInfo), isAnonymous);
    }

    rate() {
        const speakerInfo = this.#rateButton.dataset.speakerInfo;
        const speechTitle = this.#rateButton.dataset.speechTitle;
        const hall = this.#rateButton.dataset.hall;

        this.emit('rate', this.id, JSON.parse(speakerInfo), speechTitle, hall);
    }

    schedule() {
        this.emit('schedule', this.id);
    }

    #initId() {
        this.#id = Number(this.node.getAttribute('data-id'));
    }

    #initQuestionButton() {
        const questionButton = this.getSubItem('question', null);

        if(!questionButton) return;

        questionButton.addEventListener('click', () => {
            this.question();
        })

        this.#questionButton = questionButton;
    }

    #initRateButton() {
        const rateButton = this.getSubItem('rate', null);

        if(!rateButton) return;

        rateButton.addEventListener('click', () => {
            this.rate();
        })

        this.#rateButton = rateButton;
    }

    #initScheduleButton() {
        const scheduleButton = this.getSubItem('schedule', null);

        if(!scheduleButton) return;

        scheduleButton.addEventListener('click', () => {
            this.schedule();
        })

        this.#scheduleButton = scheduleButton;
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);
}

export {SpeechCard}
