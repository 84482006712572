import {Component} from "~/js/components/Component";
import {EventEmitter} from '@scripts/helpers';

class MeetingCard extends Component {
    #eventEmitter = new EventEmitter();
    #id;
    #cancelButton;
    #confirmButton;
    #declineButton;

    constructor(node, id) {
        super(node, id);

        this.#initId();
        this.#initCancelButton();
        this.#initConfirmButton();
        this.#initDeclineButton();
    }

    get id() {
        return this.#id;
    }

    get confirmButton() {
        return this.#confirmButton;
    }

    get cancelButton() {
        return this.#cancelButton;
    }

    get declineButton() {
        return this.#declineButton;
    }

    cancel() {
        this.emit('cancel', this.id);
    }

    confirm() {
        this.emit('confirm', this.id);
    }

    decline() {
        this.emit('decline', this.id);
    }

    disableButtons() {
        [this.cancelButton, this.confirmButton, this.declineButton].filter(Boolean).forEach(button => {
            button.setAttribute('disabled', true);
        })
    }

    enableButtons() {
        [this.cancelButton, this.confirmButton, this.declineButton].filter(Boolean).forEach(button => {
            button.removeAttribute('disabled');
        })
    }

    #initId() {
        this.#id = Number(this.node.getAttribute('data-meeting-id'));
    }

    #initCancelButton() {
        const cancelButton = this.getSubItem('cancel', null);

        if(!cancelButton) return;

        cancelButton.addEventListener('click', () => {
            this.cancel();
        })

        this.#cancelButton = cancelButton;
    }

    #initConfirmButton() {
        const confirmButton = this.getSubItem('confirm', null);

        if(!confirmButton) return;

        confirmButton.addEventListener('click', () => {
            this.confirm();
        })

        this.#confirmButton = confirmButton;
    }

    #initDeclineButton() {
        const declineButton = this.getSubItem('decline', null);

        if(!declineButton) return;

        declineButton.addEventListener('click', () => {
            this.decline();
        })

        this.#declineButton = declineButton;
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);
}

export {MeetingCard}
