import {Rule} from './Rule';
import {stringAtoms} from '@scripts/validation/atoms';

class StringRule extends Rule {
    name = 'string';

    validateType(value) {
        return stringAtoms.type(value);
    }

    min(min) {
        this.constraint('min', stringAtoms.min, min);

        return this;
    }

    max(max) {
        this.constraint('max', stringAtoms.max, max);

        return this;
    }

    between(min, max) {
        this.min(min).max(max);

        return this;
    }

    length(length) {
        this.constraint('length', stringAtoms.length, length);

        return this;
    }

    testRegexp(regexp) {
        this.constraint('regexp', stringAtoms.testRegexp, regexp);

        return this;
    }
}

export {StringRule};
