import {Rule} from './Rule';

function validateCompleted(inputmask) {
    return inputmask.isComplete();
}

class InputmaskRule extends Rule {
    isValidateType = false;

    completed() {
        this.constraint('complete', validateCompleted);

        return this;
    }
}

export {InputmaskRule};
