import {FormField} from './FormField';
import {EventEmitter} from '@scripts/helpers';

class RadioStars extends FormField {
    #id;
    #node;
    #radios;
    #field;
    #eventEmitter;

    constructor(node, id) {
        super(node, id);

        this.#eventEmitter = new EventEmitter();
        this.#field = this.getSubItem('field', null);
        this.#radios = Array.from(node.querySelectorAll('[data-component-radios]'));

        this.initRadios();
    }

    initRadios() {
        if (this.#radios.length) {
            this.#radios.forEach(radio => {
                radio.addEventListener('change', () => {
                    this.#field.value = radio.value;
                    this.#eventEmitter.emit('input');
                })
            });
        }
    }

    setValue(value) {
        if (this.#radios.length) {
            this.#radios.forEach(radio => {
                radio.checked = radio.value === value;
            });
        }
        return super.setValue(value);
    }

    error(message) {
        super.error(message);

        if (message) {
            this.classList.add('input--danger');
        } else {
            this.classList.remove('input--danger');
        }

        return this;
    }

    addEventListener(type, listener, options = null) {
        this.#eventEmitter.on(type, listener);

        return this;
    }
}

export {RadioStars};
