import {FormField} from '@scripts/components/FormField';
import {EventEmitter} from '@scripts/helpers';

class BaseSelect extends FormField {
    #titleTextNode;
    #optionNodes;
    #eventEmitter = new EventEmitter();

    constructor(node, id) {
        super(node, id);

        const optionNodes = Array.from(node.querySelectorAll('[data-option]'));

        this.getSubItem('title').addEventListener('click', this.toggle.bind(this));
        document.addEventListener('click', this.#maybeClose.bind(this));

        for (const optionNode of optionNodes) {
            optionNode.addEventListener('click', () => this.select(optionNode));
        }

        this.#titleTextNode = this.getSubItem('titleText');
        this.#optionNodes = optionNodes;
        this.#eventEmitter = new EventEmitter();
    }

    get defaultPropertyTarget() {
        return this.node;
    }

    get optionNodes() {
        return this.#optionNodes;
    }

    addEventListener(type, listener, options = null) {
        this.#eventEmitter.on(type, listener);

        return this;
    }

    removeEventListener(type, listener, options = null) {
        this.#eventEmitter.off(type, listener);

        return this;
    }

    emit(type, ...args) {
        this.#eventEmitter.emit(type, ...args);

        return this;
    }

    toggle() {
        this.node.toggleAttribute('data-opened');

        return this;
    }

    close() {
        this.node.removeAttribute('data-opened');

        return this;
    }

    title(title) {
        this.#titleTextNode.textContent = title;

        return this;
    }

    #maybeClose(event) {
        if (!this.node.contains(event.target)) {
            this.close();
        }

        return this;
    }

    select(selectable) {
    }
}

export {BaseSelect};
