import {BaseSelect} from './BaseSelect';

class SelectSingle extends BaseSelect {
    #selected = null;
    #title;

    constructor(node, id) {
        super(node, id);

        this.#title = this.getSubItem('title');

        const selected = node.dataset.selected;

        if (selected) {
            const option = this.optionNodes.find(option => option.dataset.value === selected);

            this.select(option);
        }
    }

    get rawValue() {
        return this.#selected?.dataset.value || null;
    }

    select(option) {
        if (this.#selected === option) return;

        if (this.#selected) {
            this.#selected.removeAttribute('data-selected');
        } else {
            this.node.removeAttribute('data-empty');
        }

        const title = option.querySelector('[data-title]').textContent;

        option.setAttribute('data-selected', '');
        this.title(title);

        this.#selected = option;
        this.emit('input', option.dataset.value, option);

        this.close();

        if (option.classList.contains('select-dropdown-option')) {
            this.#title.querySelector('span').textContent = title;
        }
    }
}

export {SelectSingle};
