import {Component} from './Component';

class Logo extends Component {
    #file = null;
    #input;
    #preview;

    constructor(node, id) {
        super(node, id);

        const input = this.getSubItem('input');
        const preview = this.getSubItem('preview');

        this.on('click', this.#uploadProxy.bind(this));
        input.addEventListener('input', this.#upload.bind(this));

        this.#input = input;
        this.#preview = preview;
    }

    isEditable() {
        return 'editable' in this.dataset;
    }

    #uploadProxy() {
        if (!this.isEditable()) return;

        this.#input.click();
    }

    #setPreview(src) {
        this.#preview.src = src;

        return this;
    }

    #setPreviewFromFile(file) {
        return this.#setPreview(URL.createObjectURL(file));
    }

    #empty(empty = true) {
        if (empty) {
            this.setAttribute('data-empty', '');
        } else {
            this.removeAttribute('data-empty');
        }
    }

    #upload() {
        const file = this.#input.files[0];

        if (!file) return;

        this.#file = file;

        this.#setPreviewFromFile(file);
        this.#empty(false);
    }

    getValue() {
        return this.#file;
    }

    setValue(value = null) {
        if (!value) {
            this.#file = null;
            this.#input.value = '';
        } else if (typeof value === 'string') {
            this.#setPreview(value);
        } else {
            this.#file = value;
            this.#setPreviewFromFile(value);
        }

        this.#empty(!Boolean(value));

        return this;
    }
}

export {Logo};
